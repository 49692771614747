import styled from 'styled-components';
import {Button} from '../../Button/Button';
import ConfirmationModal, {CancelButton} from '../../../components/Modal/ConfirmationModal';
import {Text} from '../../Text/Text';
import React from 'react';

const StyledButton = styled(Button)`
    border: none;
    width: 100%;
    margin-top: 20px;
`;

const StyledCancelButton = styled(CancelButton)`
    color: var(--color-text-alpha-50);
`;

export const ConfirmBeforeCloseModal = ({isOpen, handleConfirm, handleClose}) => {
    return (
        <ConfirmationModal isOpen={isOpen} handleClose={handleClose}>
            <ConfirmationModal.Header>Weet je het zeker?</ConfirmationModal.Header>
            <Text $noMargin $align="center">Je werk is nog niet opgeslagen. Weet je het zeker dat je de pagina wil sluiten?</Text>
            <StyledButton type="button" onClick={handleConfirm}>Ja, sluiten</StyledButton>
            <StyledCancelButton onClick={handleClose}>Annuleer</StyledCancelButton>
        </ConfirmationModal>
    );
}