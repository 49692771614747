import styled from 'styled-components';
import {Text} from '../Text/Text';
import {ReactComponent as Chevron} from '../../assets/icons/chevron.svg';

const OutOfViewAside = styled.div`
    height: 100%;
    position: absolute;
    top: 0;
    ${({$direction}) => $direction === "right" ? "right: 10px;" : "left: 80px;"};
    bottom: 0;
    display: grid;
    grid-template-rows: 44px repeat(4, 58px);
    z-index: 2;
    pointer-events: none;
`;

const OutOfViewBox = styled.div`
    grid-row: ${({$row}) => $row};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    pointer-events: none;
`;

const OutOfViewItem = styled(Text).attrs({
    $small: true,
    as: "button"
})`
    font-variant-numeric: tabular-nums;
    cursor: pointer;
    color: var(--color-text-alpha-50);
    padding: ${({$direction}) => $direction === "right" ? "8px 12px 8px 16px" : "8px 16px 8px 12px"};
    border: 2px solid var(--color-grey-20);
    background-color: var(--color-white);
    border-radius: 999px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${({$direction}) => $direction === "right" ? "row-reverse" : "row"};
    pointer-events: auto;
    
    &:hover {
        background-color: var(--color-grey-10);
    }
`;

const StyledChevron = styled(Chevron)`
    width: 18px;
    height: 18px;
    ${({$direction}) => $direction !== "right" && "transform: rotate(180deg);"};
    
    path { fill: var(--color-text-alpha-50); }
`;

export const OutOfView = ({laneRowMap, outOfViewAppointments, handleOutOfViewScroll, direction = "left"}) => {
    return (
        <OutOfViewAside $direction={direction}>
            {Object.entries(laneRowMap)?.map(([key, val], index) => (
                outOfViewAppointments?.[key]?.[direction] > 0 && (
                    <OutOfViewBox key={index} $row={val + 1}>
                        <OutOfViewItem onClick={() => handleOutOfViewScroll(direction, key, outOfViewAppointments?.[key])} $direction={direction}>
                            <StyledChevron $direction={direction} /> {outOfViewAppointments[key][direction]}
                        </OutOfViewItem>
                    </OutOfViewBox>
                )
            ))}
        </OutOfViewAside>
    );
}