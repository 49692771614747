import {ReactComponent as SaveIcon} from '../../../assets/icons/save.svg';
import styled from 'styled-components';
import {Spinner} from '../../Spinner/Spinner';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {Statuses} from '../../../constants/enums';
import {NewTooltip} from '../../NewTooltip/NewTooltip';

const StyledSaveButton = styled.button`
    background-color: rgba(var(--color-blue-50-rgb), .1);
    border: none;
    cursor: pointer;
    border-radius: 8px;
    padding: 8px;

    .icon-enter {
        opacity: 0;
        transform: scale(0.85);
    }
    .icon-enter-active {
        opacity: 1;
        transform: scale(1);
        transition: opacity .2s, transform .2s;
    }
    .icon-exit {
        opacity: 1;
        transform: scale(1);
    }
    .icon-exit-active {
        opacity: 0;
        transform: scale(0.85);
        transition: opacity .2s, transform .2s;
    }
`;

const StyledSaveIcon = styled(SaveIcon)`
    display: block;
    width: 24px;
    height: 24px;
`;

const StyledSpinner = styled(Spinner)`
    display: block;
    width: 24px;
    height: 24px;
`;

export const SaveButton = ({className, submitStatus, ...props}) => {
    const pending = submitStatus === Statuses.SUBMITTING;
    const disabled = pending || submitStatus === Statuses.SILENT_SUBMITTING;

    return (
        <NewTooltip content="Opslaan" side="bottom" align="center">
            <StyledSaveButton
                type="submit"
                className={className}
                disabled={disabled}
                {...props}
            >
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={pending ? "spinner" : "save"}
                        timeout={200}
                        classNames="icon"
                    >
                        {pending
                            ? <StyledSpinner />
                            : <StyledSaveIcon />
                        }
                    </CSSTransition>
                </SwitchTransition>
            </StyledSaveButton>
        </NewTooltip>
    );
}