import {CustomLink} from '../../../components/CustomLink/CustomLink';
import React from 'react';
import styled from 'styled-components';
import {Heading3} from '../../../components/Heading/Heading';
import {Input} from '../../../components/Input/Input';
import {Text} from '../../../components/Text/Text';
import {AspectRatio} from '../../../components/AspectRatio/AspectRatio';
import emailPreviewVideo from '../../../assets/email-preview-video.png';
import {ReactComponent as Logo} from '../../../assets/logo.svg';

const StyledEmailPreview = styled.div``;

const Label = styled(Heading3).attrs({
    as: "p",
    $noMargin: true
})`
    margin-bottom: 10px;
`;

const Preview = styled(Input).attrs({
    as: "div",
})`
    height: 200px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const PreviewText = styled(Text).attrs({
    $noMargin: true
})`
    white-space: pre-wrap;
`;

const PreviewList = styled(PreviewText).attrs({
    as: "ol"
})``;

const PreviewImage = styled(AspectRatio)`
    max-width: 200px;
`;

const PreviewLogo = styled(Logo)`
    flex-shrink: 0;
    display: block;
    width: 104px;
    height: 88px;
    object-fit: cover;
`;

const PreviewLine = styled.hr`
    margin: 0;
`;

export const EmailPreview = ({watchCustomTextNL, watchCustomTextEN}) => {
    return (
        <StyledEmailPreview>
            <Label>Uitnodigingsmail preview</Label>
            <Preview>
                <PreviewText>Goedendag,</PreviewText>
                <PreviewText>{watchCustomTextNL}</PreviewText>
                <PreviewList>
                    <li><strong>Informeer jezelf</strong> over de voorbereiding en invulling van de diagnostiekdag en informatie rondom kosten en vergoeding door deze mail goed door te lezen en het filmpje en de link te bekijken.</li>
                    <li><strong>Vul de vragenlijst in.</strong> Om je zo goed mogelijk te kunnen helpen, vragen we je de "screenings-vragenlijst" in te vullen. Voor alle vragen geldt: Er is geen 'goed' of 'fout' antwoord. Het is belangrijk dat je de vragen eerlijk beantwoordt.</li>
                    <li><strong>Plaats een reserveringsaanvraag</strong> via de reserveringspagina. Is er op de locatie van jouw voorkeur geen plek meer, kijk dan ook eens bij een wat verder weg gelegen locatie voor een plek op kortere termijn*.</li>
                    <li><strong>Bel binnen 5 werkdagen</strong> het telefoonnummer dat staat in de bevestigingsmail zodat we je aanvraag telefonisch door kunnen lopen en eventuele bijzonderheden kunnen bespreken. Pas na dit telefonische contact kan jouw reservering omgezet worden in een <strong>definitieve afspraak</strong>. Jouw reservering is 5 werkdagen geldig. Spreken wij elkaar niet binnen deze termijn, dan vervalt de reservering.</li>
                </PreviewList>
                <PreviewText $italic>*Je reist dan eenmalig wat verder voor de diagnostiekdag. Evt. vervolgbehandelingen kunnen in overleg online gevolgd worden. Echter, het is <strong>niet</strong> mogelijk om deze op een voor jou dichterbij gelegen locatie te volgen. Tijdens het telefoongesprek waarin we jouw situatie bespreken, kijken we dan of deze vorm van online behandeling voor jou geschikt is.</PreviewText>
                <PreviewText><strong>Stap 1</strong><br/>Bekijk hier een informatiefilmpje over het verloop van de diagnostiekdag. Je krijgt dan een goed beeld over hoe die dag bij ADHDcentraal verloopt.</PreviewText>
                <CustomLink to="https://adhdcentraal.nl/diagnostiek/" type="external">
                    <PreviewImage src={emailPreviewVideo} alt="" />
                </CustomLink>
                <PreviewText>Kijk voor de actuele en volledige informatie over de vergoeding via jouw zorgverzekeraar op: <CustomLink to="https://adhdcentraal.nl/wij-bieden/vergoeding" type="external">https://adhdcentraal.nl/wij-bieden/vergoeding</CustomLink></PreviewText>
                <PreviewText>Is alles duidelijk?</PreviewText>
                <PreviewText><CustomLink to="https://reservering.adhdcentraal.nl/" type="external">Ga dan aan de slag met stap 2 via onze reserveringspagina</CustomLink></PreviewText>
                <PreviewText>Heb je geen behoefte meer aan onze zorg (bijvoorbeeld omdat er reeds elders zorg is gevonden) dan kan je dat <CustomLink to="https://adhdcentraal.nl/uitschrijven-wachtlijst/" type="external">hier</CustomLink> aangeven. Wij zullen je dan niet meer benaderen.</PreviewText>
                <PreviewText>Met vriendelijke groet,<br/>Team ADHDcentraal</PreviewText>
                <PreviewLogo />
                <PreviewLine />
                <PreviewText>Good day,</PreviewText>
                <PreviewText>{watchCustomTextEN}</PreviewText>
                <PreviewList>
                    <li><strong>Inform yourself</strong> about the preparation and details of the diagnostic day, as well as information about costs and reimbursement, by carefully reading this email and watching the video (in Dutch only) and link provided.</li>
                    <li><strong>Fill out the questionnaire.</strong> To assist you as best as we can, we ask you to complete the "screening questionnaire." For all questions, there is no 'right' or 'wrong' answer. It’s important that you answer the questions honestly.</li>
                    <li><strong>Submit a reservation request</strong> via the reservation page. If your preferred location is fully booked, consider checking other locations that may have availability sooner*.</li>
                    <li><strong>Call the phone number</strong> provided in the confirmation email <strong>within 5 business days</strong> so we can review your request over the phone and discuss any special considerations. Only after this phone contact can your reservation be converted into a <strong>final appointment</strong>. Your reservation is valid for 5 business days. If we do not speak within this period, the reservation will expire.</li>
                </PreviewList>
                <PreviewText $italic>*You may need to travel a bit further for the diagnostic day, but any follow-up treatments, by mutual agreement, can be arranged online. Please note, it is <strong>not</strong> possible to follow treatment at a location closer to you. During the phone conversation where we discuss your situation, we will then see if this form of online treatment is right for you.</PreviewText>
                <PreviewText><strong>Step 1</strong><br/>Watch this informational video about how the diagnostic day unfolds. It will give you a good idea of what to expect at ADHDcentraal.</PreviewText>
                <CustomLink to="https://adhdcentraal.nl/en/diagnostics/" type="external">
                    <PreviewImage src={emailPreviewVideo} alt="" />
                </CustomLink>
                <PreviewText>For up-to-date and complete information about reimbursement through your health insurer, visit: <CustomLink to="https://adhdcentraal.nl/en/fee" type="external">https://adhdcentraal.nl/en/fee</CustomLink></PreviewText>
                <PreviewText>Is everything clear?</PreviewText>
                <PreviewText><CustomLink to="https://reservering.adhdcentraal.nl/" type="external">Then proceed with step 2 via our reservation page</CustomLink></PreviewText>
                <PreviewText>If you no longer require our services (for example, because you have found care elsewhere), you can indicate so <CustomLink to="https://adhdcentraal.nl/en/unsubscribe-waiting-list/" type="external">here</CustomLink>. We will then no longer contact you.</PreviewText>
                <PreviewText>Kind regards,<br/>Team ADHDcentraal</PreviewText>
                <PreviewLogo />
            </Preview>
        </StyledEmailPreview>
    );
}