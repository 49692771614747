import styled, {css} from 'styled-components';

export const truncateMixin = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ $clamp = 1 }) => `-webkit-line-clamp: ${$clamp};`}
`;

export const Text = styled.p`
    font-size: ${({$small}) => $small ? 'var(--fs-text-small)' : 'var(--fs-text)'};
    color: ${({$error, $white, $dark}) => $error ? 'var(--color-error)' : $white ? 'var(--color-white)' : $dark ? 'var(--color-dark-rgb-50)' : 'var(--color-text)'};
    line-height: ${({$lineHeight}) => $lineHeight ?? 1.25};
    ${({$noMargin}) => $noMargin && 'margin: 0;'};
    overflow-wrap: anywhere;
    text-align: ${({$align}) => $align === "center" ? "center" : "left"};
    ${({$italic}) => $italic && 'font-style: italic;'};
    ${({$bold}) => $bold && 'font-weight: var(--fw-bold);'};
    ${({ $truncate }) => $truncate && truncateMixin};
    ${({$margin}) => $margin && `margin: ${$margin};`};
    
    a {
        color: var(--color-primary);
        
        &:hover {
            color: var(--color-primary-hover);
            text-decoration: none;
        }
    }
`;

export const HTMLText = styled(Text).attrs({
    as: "div",
})`
    strong { font-weight: var(--fw-bold); }
    em { font-style: italic; }
    ul { padding-left: 20px; }
    ol { padding-left: 20px; }
`;

export const MarkdownText = ({className, text, ...props}) => {
    if (!text) return null;

    const parseMarkdown = (input) => {
        let output = input;

        // Bold
        output = output.replace(/\*\*(.*?)\*\*/gim, '<strong>$1</strong>');

        // Italic
        output = output.replace(/\*(.*?)\*/gim, '<em>$1</em>');

        // Links
        output = output.replace(/\[([^\]]+)\]\(([^)]+)\)/gim, '<a href="$2" target="_blank" rel="noreferrer">$1</a>');

        // Unordered List
        output = output.replace(/^\s*[-*] (.*$)/gim, '<li>$1</li>');

        // Ordered List
        output = output.replace(/(<li>.*<\/li>)/gim, '<ul>$1</ul>');

        // New lines
        output = output.replace(/\n/g, '<br />');

        return output.trim();
    };

    const parsedText = parseMarkdown(text);

    return (
        <Text
            className={className}
            dangerouslySetInnerHTML={{ __html: parsedText }}
            {...props}
        />
    );
}