import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import styled from 'styled-components';

const TooltipProvider = TooltipPrimitive.Provider;
const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipArrow = TooltipPrimitive.Arrow;
const TooltipPortal = TooltipPrimitive.Portal;

const StyledNewTooltipBase = styled(TooltipPrimitive.Content)`
    border-radius: 8px;
    padding: 12px 16px;
    background-color: var(--color-primary);
    z-index: 100;
    max-width: 290px;
    color: var(--color-white);
    font-size: var(--fs-text-small);
    font-weight: var(--fw-regular);
    line-height: 1.25;
    box-shadow: var(--box-shadow);
`;

const TooltipContent = React.forwardRef(({ className, sideOffset = 4, ...props }, ref) => (
    <StyledNewTooltipBase
        ref={ref}
        sideOffset={sideOffset}
        className={className}
        {...props}
    />
))

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { TooltipRoot, TooltipTrigger, TooltipContent, TooltipProvider, TooltipArrow, TooltipPortal }