import {Table, TableHead, TableHeadCell, TableHeadCellSubtitle, TableHeadRow, TableRow, TableRowCell, TableRowInner} from '../../../components/Table/Table';
import {isObject} from '../../../utils/helpers';
import {ConditionalWrap} from '../../../components/Utils/Utils';
import React from 'react';
import styled from 'styled-components';
import {CustomLink} from '../../../components/CustomLink/CustomLink';
import {useParams} from 'react-router-dom';

const StyledTable = styled(Table)`
    margin-top: 60px;
`;

const StyledCustomLink = styled(CustomLink)`
    text-decoration: underline;
    color: inherit;
    
    &:hover {
        text-decoration: none;
    }
`;

// NOTE: This is the stale variant of the ResultTable and is working for older questionnaire results.
export const ResultTableStale = ({questionnaireData}) => {
    const isDivaResult = questionnaireData?.result?.type === "DivaResult" || questionnaireData?.result?.type === "DivaResultEnglish";
    const isCaarsFellowResult = questionnaireData?.result?.type === "CaarsFellowResult";
    const {patientUUID} = useParams();

    return (
        <StyledTable>
            <TableHead>
                <TableHeadRow>
                    {questionnaireData?.result?.content?.head?.[0]?.cells?.map((item, index) => (
                        <TableHeadCell
                            key={isObject(item) ? `${index}-${item?.title}` : `${index}-${item}`}
                            $flexGrow={(!isDivaResult && !isCaarsFellowResult && index === 0) ? 3 : undefined}
                            $minWidth="unset"
                            $isGrayedOut={isObject(item) ? item?.isGrayedOut : false}
                        >
                            {isObject(item) ? (
                                <ConditionalWrap condition={Boolean(item.id)} wrap={children => <StyledCustomLink to={`/patient/${patientUUID}/vragenlijst/${item.id}`}>{children}</StyledCustomLink>}>
                                    {item?.title}
                                </ConditionalWrap>
                            ) : item}
                            {(isObject(item) && item?.subtitle) &&
                                <TableHeadCellSubtitle $isGrayedOut={item?.isGrayedOut}>{item.subtitle}</TableHeadCellSubtitle>
                            }
                        </TableHeadCell>
                    ))}
                </TableHeadRow>
            </TableHead>

            {questionnaireData?.result?.content?.body?.map((item, rowIndex, {length}) => {
                const key = `${rowIndex}-${item?.cells?.[0]}`;
                const isOddRow = rowIndex % 2 === 0;

                return (
                    <TableRow key={key}>
                        <TableRowInner $isOddRow={isOddRow}>
                            {item.cells.map((cell, cellIndex) => (
                                <TableRowCell
                                    key={isObject(cell) ? `${cellIndex}-${cell?.content}` : `${cellIndex}-${cell}`}
                                    $variant={(!isDivaResult && !isCaarsFellowResult && length > 1 && rowIndex === length - 1) ? "total" : cellIndex === 0 ? "title" : undefined}
                                    $flexGrow={(!isDivaResult && !isCaarsFellowResult && cellIndex === 0) ? 3 : undefined}
                                    $minWidth="unset"
                                    $isGrayedOut={isObject(cell) ? cell?.isGrayedOut : false}
                                >
                                    {isObject(cell) ? cell?.content : cell}
                                </TableRowCell>
                            ))}
                        </TableRowInner>
                    </TableRow>
                );
            })}

            {questionnaireData?.result?.total &&
                <TableRow as="div">
                    <TableRowInner $isOddRow={questionnaireData?.result?.categories?.length % 2 === 0}>
                        <TableRowCell $title $flexGrow={3} $minWidth="unset">{questionnaireData.result.total.totalTitle}</TableRowCell>
                        <TableRowCell $variant="title" $minWidth="unset">{questionnaireData.result.total.totalQuestions}</TableRowCell>
                        <TableRowCell $variant="title" $minWidth="unset">{questionnaireData.result.total.totalScore}</TableRowCell>
                        <TableRowCell $variant="title" $minWidth="unset">{questionnaireData.result.total.yourTotalScore}</TableRowCell>
                    </TableRowInner>
                </TableRow>
            }
        </StyledTable>
    );
}