import {Input} from '../Input/Input';

export const TextInputField = ({data, ...props}) => {
    return (
        <Input
            id={props.id}
            type="text"
            placeholder={props.placeholder}
            defaultValue={data?.answer}
            {...(props.register && props.register(props.name, {
                required: props.required,
                ...(props.maxLength && {maxLength: props.maxLength}),
                ...(props.pattern && {pattern: {value:props.pattern, message: props.patternMessage ? props.patternMessage : "Het formaat is ongeldig"}}),
                onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() }
            }))}
            disabled={props.disabled}
        />
    );
}