import {createPortal} from 'react-dom';
import {Transition} from 'react-transition-group';
import {forwardRef, useRef} from 'react';
import styled, {css} from 'styled-components';

export const OuterModal = styled.div`
    background-color: ${({$isMinimized}) => $isMinimized ? "transparent" : "rgba(0, 0, 0, .5)"};
    ${({$isMinimized, $shouldConfirmOnClickUnderlay}) => ($isMinimized && !$shouldConfirmOnClickUnderlay) && "pointer-events: none;"};
    ${({$blurredBackground}) => $blurredBackground && css`
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
    `};
    position: fixed;
    inset: 0;
    z-index: 100;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: 1; }
    &.entered { opacity: 1; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

const StyledModal = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 20px;
`;

const InnerModal = styled.div`
    height: fit-content;
    margin: auto;
`;

export const Modal = forwardRef(function Modal(props, ref) {
    const {className, isOpen, handleClose, doNotCloseOnClickUnderlay, blurredBackground, onExited, id, children, isMinimized = false, shouldConfirmOnClickUnderlay = false} = props;

    const nodeRef = useRef();

    return createPortal(
        <>
            <Transition nodeRef={nodeRef} in={isOpen} timeout={200} unmountOnExit onExited={onExited}>
                {state => (
                    <OuterModal ref={nodeRef} className={state} onClick={!doNotCloseOnClickUnderlay ? handleClose : undefined} $blurredBackground={blurredBackground} $isMinimized={isMinimized} $shouldConfirmOnClickUnderlay={shouldConfirmOnClickUnderlay}>
                        <StyledModal ref={ref} id={id}>
                            <InnerModal className={className} onClick={(e) => e.stopPropagation()}>
                                {children}
                            </InnerModal>
                        </StyledModal>
                    </OuterModal>
                )}
            </Transition>
        </>, document.body
    );
})