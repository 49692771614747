import {Modal} from './Modal';
import styled, {css} from 'styled-components';
import {Heading1} from '../Heading/Heading';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {forwardRef} from 'react';

const StyledModal = styled(Modal)`
    max-width: 800px;
    width: 100%;
    
    ${({$isMinimized}) => $isMinimized && css`
        position: absolute;
        bottom: 0;
        right: 20px;
        max-width: 550px;
    `};
`;

const Inner = styled.div`
    pointer-events: initial;
    position: relative;
    width: 100%;
    background-color: var(--color-content-background);
    box-shadow: var(--box-shadow-minimizable-modal);
    border-radius: ${({$isMinimized}) => $isMinimized ? "20px 20px 0 0" : "20px"};
    height: ${({$isMinimized}) => $isMinimized ? "650px" : "calc(100vh - 40px)"};;
    max-height: 100%;
    overflow-y: auto;
`;

const StyledSticky = styled.div`
    position: sticky;
    top: 0;
    background-color: var(--color-content-background);
    padding: ${({$isMinimized}) => $isMinimized ? "30px 30px 24px" : "50px 50px 24px"};
    
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: ${({$isMinimized}) => $isMinimized ? "30px" : "50px"});
        background-color: var(--color-divider);
        width: calc(100% - ${({$isMinimized}) => $isMinimized ? "60px" : "100px"});
        height: 2px;
    }
`;

const StyledHeader = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
`;

const Title = styled(Heading1).attrs({
    as: "h3",
    $noMargin: true
})`
    padding-top: 2px;
`;

const IconTray = styled.div`
    display: flex;
    gap: 10px;
    flex: 1;
    justify-content: flex-end;
`;

const StyledCloseIcon = styled(CloseIcon)`
    width: 40px;
    height: 40px;
    cursor: pointer;
`;

const StyledContent = styled.div`
    padding: ${({$isMinimized}) => $isMinimized ? "30px" : "50px"};
`;

const ReportingModal = forwardRef(function ReportingModal(props, ref) {
    const {children, isExpanded, ...otherProps} = props;

    return (
        <StyledModal ref={ref} isMinimized={!isExpanded} $isMinimized={!isExpanded} {...otherProps}>
            <Inner $isMinimized={!isExpanded}>
                {children}
            </Inner>
        </StyledModal>
    );
})

ReportingModal.Sticky = function Sticky({children, isExpanded}) {
    return (
        <StyledSticky $isMinimized={!isExpanded}>
            {children}
        </StyledSticky>
    );
}

ReportingModal.Header = function Header({children, icons, handleClose}) {
    return (
        <StyledHeader>
            <Title>{children}</Title>
            <IconTray>
                {icons}
                <StyledCloseIcon onClick={handleClose}/>
            </IconTray>
        </StyledHeader>
    );
}

ReportingModal.Content = function Content({children, isExpanded}) {
    return (
        <StyledContent $isMinimized={!isExpanded}>
            {children}
        </StyledContent>
    );
}

export default ReportingModal;