import styled from "styled-components";
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useDebouncedCallback} from "use-debounce";
import {useFetcher, useParams} from 'react-router-dom';
import {Statuses} from '../../../constants/enums';
import {getFormData} from '../../../utils/getFormData';
import {DefaultFormFields} from '../../DefaultFormFields/DefaultFormFields';
import {Text} from '../../Text/Text';

const StyledDefaultFormFields = styled(DefaultFormFields)`
    margin-top: 0;
`;

function Report({reportData, appointment, setSubmitStatus, setHasUnsavedChanges}) {
    const {patientUUID} = useParams();
    const fetcher = useFetcher();
    const [error, setError] = useState(false);

    const {register, handleSubmit, formState: {errors}, getValues, unregister, watch, control} = useForm({
        defaultValues: {
            intent: "timeline-appointment-report",
            appointmentUUID: appointment.id,
            ...extractCheckboxAnswers(reportData?.items)
        }
    });

    const onSubmit = (data, event, type) => {
        setSubmitStatus(type === Statuses.SILENT_SUBMITTING ? Statuses.SILENT_SUBMITTING : Statuses.SUBMITTING);
        setError(false);

        const filteredData = getFormData(data);
        fetcher.submit(filteredData, {method: "POST", action: `/patient/${patientUUID}`})
    }

    // Fetcher callback
    useEffect(() => {
        // When submitting report, handle before revalidating (state === loading) to remove confirmBeforeCloseModal earlier
        if (fetcher?.state === "idle" || fetcher?.state === "loading") {
            if (fetcher?.data?.error) {
                setSubmitStatus(Statuses.IDLE);
                return setError(true);
            }

            if (fetcher?.data) {
                setSubmitStatus(Statuses.IDLE);
                setHasUnsavedChanges(false);
            }
        }
        //eslint-disable-next-line
    }, [fetcher.state, fetcher.data]);

    // Debounce submissions when making changes in the form
    const debouncedSubmit = useDebouncedCallback(() => {
        onSubmit(getValues(), null, Statuses.SILENT_SUBMITTING);
    }, 2000)

    // Subscribe to entire form changes
    useEffect(() => {
        const {unsubscribe} = watch(() => {
            setHasUnsavedChanges(true);
            debouncedSubmit();
        })

        return () => unsubscribe();
        //eslint-disable-next-line
    }, [watch]);

    return (
        <>
            {error && <Text $error $margin="0 0 30px 0">Er is iets misgegaan, probeer het opnieuw.</Text>}

            <form id="timeline-appointment-report-form" onSubmit={handleSubmit(onSubmit)}>
                <StyledDefaultFormFields
                    currentStepData={reportData}
                    errors={errors}
                    register={register}
                    getValues={getValues}
                    unregister={unregister}
                    watch={watch}
                    control={control}
                />
            </form>
        </>
    );

    // Set defaultValues for checkboxes, because CheckboxesField is controlled (Without steps, as in Questions.jsx)
    function extractCheckboxAnswers(items) {
        return items?.reduce((result, item) => {
            if (item.type === "Checkboxes" && Boolean(item?.answer)) {
                const answerIds = Array(item.options?.length).fill(null);  // Init array with null values based on length of options array

                // Add the answers to the same index as the option, since RHF needs to know the option index (see CheckboxesField)
                item.answer.forEach(answer => {
                    const optionIndex = item.options.findIndex(option => option.id === answer.id);
                    if (optionIndex !== -1) {
                        answerIds[optionIndex] = answer.id;
                    }
                });

                result[item.id] = answerIds;
            }
            return result;
        }, {});
    }
}

export default Report