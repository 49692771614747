import {Outlet, useLoaderData, useLocation, useNavigate, useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import {fetchProtectedData} from "../api/fetch";
import {checkRequiredAccount, resetRootLoaderPromise} from '../routes/Root/Root';
import {SideBar} from '../components/SideBar/SideBar';
import {getCurrentEnvironment} from '../utils/helpers';
import {TopBar} from '../components/TopBar/TopBar';
import React from 'react';
import {useOpenModal} from '../hooks/useOpenModal';
import ConfirmationModal from '../components/Modal/ConfirmationModal';
import {Text} from '../components/Text/Text';
import {Button} from '../components/Button/Button';
import Cookies from 'js-cookie';
import {useTokenExpirationCheck} from '../hooks/useTokenExpirationCheck';
import {NewsPopup} from '../components/NewsPopup/NewsPopup';
import {ImpersonateBar} from '../components/ImpersonateBar/ImpersonateBar';

const StyledPortalLayout = styled.div`
    background-color: var(--color-background);
    min-height: 100vh;
`;

const EnvironmentBar = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 20px;
    z-index: 999;
    background-color: ${({$type}) => $type && `var(--color-environment-${$type})`};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: var(--fs-environment);
    color: var(--color-text);
    font-weight: var(--fw-bold);
`;

const Content = styled.div`
    ${({$hasEnvironmentBar}) => $hasEnvironmentBar && "padding-top: 20px;"};
    position: relative;
    margin-left: 92px; // SideBar width
    margin-bottom: 50px;
`;

const InnerContent = styled.div`
    padding: 20px 60px 60px 60px;
`;

const StyledButton = styled(Button)`
    border: none;
    width: 100%;
    margin-top: 20px;
`;

/* TODO: remove test data */
const latestNewsItem = {
    id: 1,
    image: "https://placehold.co/600x600",
    title: "Nieuw: het berichtencentrum",
    date: "16 juni 2024",
    text: "<p>Het is nu mogelijk om in het portaal, bij de persoonlijke pagina van een patiënt, een app account voor diegene aan te maken. Ook kan een app account van een patiënt juist weer verwijderd worden.</p>"
}

export async function portalLoader({ request }) {
    await checkRequiredAccount();

    const [locationData, newsData] = await Promise.all([
        fetchProtectedData(request, 'location'),
        fetchProtectedData(request, 'news')
    ]);

    return { locationData, newsData };
}

export default function PortalLayout() {
    const {account} = useRouteLoaderData("root");

    const { locationData, newsData } = useLoaderData()
    const currentEnvironment = getCurrentEnvironment();
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const navigate = useNavigate();
    const location = useLocation();
    useTokenExpirationCheck(handleOpen, handleClose, handleLogout); // Show popup when token is about to expire

    function handleLogout () {
        Cookies.remove('adhdcentraal-portal_token');
        Cookies.remove('adhdcentraal-portal_expiration');

        resetRootLoaderPromise();

        navigate("/login", { replace: true, state : { from: location, reason: "token_expired" } })
    }

    return (
        <>
            <StyledPortalLayout>
                {currentEnvironment.showBar &&
                    <EnvironmentBar $type={currentEnvironment.type}>{currentEnvironment.label}</EnvironmentBar>
                }

                { account?.impersonatedPerson &&
                    <ImpersonateBar name={account.impersonatedPerson.fullName}/>
                }

                <SideBar />

                <Content $hasEnvironmentBar={currentEnvironment.showBar}>
                    <TopBar locationData={locationData} newsData={newsData} />
                    <InnerContent>
                        <Outlet />
                    </InnerContent>
                </Content>
            </StyledPortalLayout>

            <NewsPopup data={latestNewsItem} />

            <ConfirmationModal isOpen={isOpen} handleClose={handleClose} doNotCloseOnClickUnderlay={true} blurredBackground={true}>
                <ConfirmationModal.Header>Je wordt uitgelogd</ConfirmationModal.Header>
                <Text $noMargin $align="center">Je huidige sessie is verlopen. Log opnieuw in om verder te gaan. Je voortgang is opgeslagen.</Text>
                <StyledButton type="button" onClick={handleLogout}>Opnieuw inloggen</StyledButton>
            </ConfirmationModal>
        </>
    );
}