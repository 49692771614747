import {Text} from '../../Text/Text';
import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';

const DateTime = styled(Text).attrs({
    $small: true,
    $noMargin: true
})``;

export const AppointmentDateTime = ({className, appointment}) => {
    const isDiagnosticsDay = appointment.category === "Diagnostiekdag";
    const isReservedDiagnosticsDay = isDiagnosticsDay && appointment.status === "Reserved";

    return (
        <DateTime className={className} $dark={isReservedDiagnosticsDay}>
            <strong>{appointment.date && dayjs(appointment.date).format("D MMM YYYY")}</strong>
            {(appointment.actualStart == null && appointment.actualEnd == null)
                ? `, ${dayjs(appointment.start).format("HH:mm")} - ${dayjs(appointment.end).format("HH:mm")}`
                : `, ${dayjs(appointment.actualStart).format("HH:mm")} - ${dayjs(appointment.actualEnd).format("HH:mm")}`
            }
        </DateTime>
    );
}