import {useParams} from 'react-router-dom';
import {useOpenModal} from '../../../hooks/useOpenModal';
import React, {useEffect, useState} from 'react';
import {Statuses} from '../../../constants/enums';
import Api from '../../../api/Api';
import {Text} from '../../../components/Text/Text';
import styled from 'styled-components';
import {ReactComponent as Info} from '../../../assets/icons/info2.svg';
import ContentModal from '../../../components/Modal/ContentModal';
import {Heading3} from '../../../components/Heading/Heading';
import {Spinner, SpinnerWrapper} from '../../../components/Spinner/Spinner';
import {ReactComponent as DirectingPractitioner} from "../../../assets/icons/directing_practitioner.svg";

const InfoIcon = styled(Info)`
    width: 20px;
    height: 20px;
    vertical-align: top;
    cursor: pointer;
    margin-left: 8px;
`;

const CareTeamItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CareTeamItemTextWrapper = styled.div`
    display: flex;
`;

const CareTeamItemText = styled(Text).attrs({
    $noMargin: true
})`
    min-height: 20px;
`;

const DirectingPractitionerIcon = styled(DirectingPractitioner)`
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    vertical-align: bottom;
    opacity: ${({$doNotShow}) => $doNotShow === true ?  0 :  1};
`;

const Card = styled.div`
    background-color: var(--color-white);
    border-radius: 16px;
    box-shadow: var(--box-shadow);
    padding: 24px;

    &:first-of-type {
        margin-bottom: 24px;
    }
`;

const Header = styled.div`
    display: flex;
    gap: 10px;
`;

const HeaderItem = styled(Heading3).attrs({
    $noMargin: true
})`
    &:first-of-type {
        font-size: var(--fs-heading-2);
        flex-basis: 140px;
        flex-shrink: 0;
    }

    &:nth-of-type(2) {
        gap: 10px;
        flex-basis: 25%;
        flex-grow: 1;
        padding-left: 30px;
    }

    flex-basis: 15%;
`;

const Lane = styled.div`
    display: flex;
    padding: 14px 0 14px 0;
    gap: 10px;
    overflow: hidden;

    &:first-of-type {
        margin-top: 20px;
    }

    &:last-of-type {
        padding-bottom: 0;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(var(--color-grey-20-rgb), .5);
    }
`;

const Role = styled(Heading3).attrs({
    $noMargin: true,
})`
    flex-shrink: 0;
    flex-basis: 140px;
    background-color: white;
`;

const NamesWrapper = styled.div`
    display: flex;
    gap: 10px;
    flex-basis: 25%;
    flex-grow: 1;
`;

const Names = styled.div`
    display: flex;
    flex-direction: column;
    gap: 28px;
`;

const NameWrapper = styled.div`
    position: relative;
`;

const Divider = styled.div`
    position: absolute;
    top: calc(100% + 14px);
    left: 0;
    transform: translateY(50%);
    height: 2px;
    width: 100vw;
    background-color: rgba(var(--color-grey-20-rgb), .5);
`;

const DirectingPractitioners = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: white;
    justify-content: space-between;
`;

const StartDates = styled(Names)`
    flex-basis: 15%;
`;
const EndDates = styled(Names)`
    flex-basis: 15%;
`;

const Name = styled(Text).attrs({
    $noMargin: true,
})``;

const Start = styled(Name)``;
const End = styled(Name)``;

const StyledSpinnerWrapper = styled(SpinnerWrapper)`
    min-height: 152px;
`;

export const CareTeam = () => {
    const {patientUUID} = useParams();
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const [status, setStatus] = useState(Statuses.LOADING);
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);

    const psoTeamCurrent = data?.current?.PSO?.length > 0 ? data?.current?.PSO : []
    const vsTeamCurrent = data?.current?.VS?.length > 0 ? data?.current?.VS : []
    const psaTeamCurrent = data?.current?.PSA?.length > 0 ? data?.current?.PSA : []

    const psoTeamOld = data?.old?.PSO?.length > 0 ? data?.old?.PSO : []
    const vsTeamOld = data?.old?.VS?.length > 0 ? data?.old?.VS : []
    const psaTeamOld = data?.old?.PSA?.length > 0 ? data?.old?.PSA : []

    useEffect(() => {
        if (data) setStatus(Statuses.REVALIDATING);

        const controller = new AbortController();

        Api.get(`patient/${patientUUID}/careteam`, (res) => {
            setData(res);
            setError(false);
        }, () => {
            setError(true);
        }, controller).then(() => setStatus(Statuses.IDLE));

        return () => {
            Api.abortRequest(controller);
        };
        // eslint-disable-next-line
    }, []); // run on mount

    const TeamLane = ({title, data}) => {
        return (
            <Lane>
                <Role>{title}</Role>
                <NamesWrapper>
                    <DirectingPractitioners>
                        {/* eslint-disable-next-line array-callback-return */}
                        {data?.map((item, index) => {
                            if (item.director === true) {
                                return <DirectingPractitionerIcon key={index}/>
                            } else {
                                return <DirectingPractitionerIcon key={index} $doNotShow={true}/>
                            }
                        })}
                    </DirectingPractitioners>
                    {/* eslint-disable-next-line array-callback-return */}
                    <Names>
                        {data?.map((item, index, {length}) => (
                            <NameWrapper key={index}>
                                <Name>{item.name}</Name>
                                {index !== length - 1 && <Divider/>}
                            </NameWrapper>
                        ))}
                    </Names>

                </NamesWrapper>
                <StartDates>
                    {/* eslint-disable-next-line array-callback-return */}
                    {data?.map((item, index) => (
                        <Start key={index}>{item.start}</Start>
                    ))}
                </StartDates>
                <EndDates>
                    {/* eslint-disable-next-line array-callback-return */}
                    {data?.map((item, index) => {
                        if (item.start !== null && item.end === null) {
                            return <End key={index}>--</End>
                        }
                        return <End key={index}>{item.end}</End>
                    })}
                </EndDates>
            </Lane>
        )
    };

    const CareTeamItems = () => {
        const VSNames =
            vsTeamCurrent.map((item, index) => (
                <React.Fragment key={index}>
                    {item.name}{item.director && <> <DirectingPractitionerIcon/></>}{index !== vsTeamCurrent.length - 1 && ', '}
                </React.Fragment>
            ));
        const PSANames =
            psaTeamCurrent.map((item, index) => (
            <React.Fragment key={index}>
                {item.name}{item.director && <> <DirectingPractitionerIcon/></>}{index !== psaTeamCurrent.length - 1 && ', '}
            </React.Fragment>
        ));
        const PSONames =
            psoTeamCurrent.map((item, index) => (
            <React.Fragment key={index}>
                {item.name}{item.director && <> <DirectingPractitionerIcon/></>}{index !== psoTeamCurrent.length - 1 && ', '}
            </React.Fragment>
        ));

        return (
            <CareTeamItemWrapper>
                <Text $margin={"0 0 6px 0"} $bold>
                    Behandelteam
                    <InfoIcon onClick={handleOpen}/>
                </Text>
                <CareTeamItemTextWrapper>
                    <CareTeamItemText>
                        <strong>VS:</strong> {VSNames}
                    </CareTeamItemText>
                </CareTeamItemTextWrapper>
                <CareTeamItemTextWrapper>
                    <CareTeamItemText>
                        <strong>PSA:</strong> {PSANames}
                    </CareTeamItemText>
                </CareTeamItemTextWrapper>
                <CareTeamItemTextWrapper>
                    <CareTeamItemText>
                        <strong>PSO:</strong> {PSONames}
                    </CareTeamItemText>
                </CareTeamItemTextWrapper>
            </CareTeamItemWrapper>
        )
    }

    return (
        <>
            <CareTeamItems/>

            <ContentModal isOpen={isOpen} handleClose={handleClose}>
                <ContentModal.Header handleClose={handleClose}>Behandelteam</ContentModal.Header>
                <Card>
                    <Header>
                        <HeaderItem>Huidig team</HeaderItem>
                        <HeaderItem>Behandelaar</HeaderItem>
                        <HeaderItem>Start</HeaderItem>
                        <HeaderItem>Eind</HeaderItem>
                    </Header>

                    {status === Statuses.LOADING ? (
                        <StyledSpinnerWrapper>
                            <Spinner/>
                        </StyledSpinnerWrapper>
                    ) : (
                        <>
                            <TeamLane title={"VS"} data={vsTeamCurrent}></TeamLane>
                            <TeamLane title={"Psychiater"} data={psaTeamCurrent}></TeamLane>
                            <TeamLane title={"Psycholoog"} data={psoTeamCurrent}></TeamLane>
                        </>
                    )}
                </Card>

                <Card>
                    <Header>
                        <HeaderItem>Voormalig team</HeaderItem>
                        <HeaderItem>Behandelaar</HeaderItem>
                        <HeaderItem>Start</HeaderItem>
                        <HeaderItem>Eind</HeaderItem>
                    </Header>

                    {status === Statuses.LOADING ? (
                        <StyledSpinnerWrapper>
                            <Spinner/>
                        </StyledSpinnerWrapper>
                    ) : (
                        <>
                            <TeamLane title={"VS"} data={vsTeamOld}></TeamLane>
                            <TeamLane title={"Psychiater"} data={psaTeamOld}></TeamLane>
                            <TeamLane title={"Psycholoog"} data={psoTeamOld}></TeamLane>
                        </>
                    )}
                </Card>

                {error && <Text>Het behandelteam kan niet worden opgehaald, probeer het opnieuw.</Text>}
            </ContentModal>
        </>
    );
}