export const getFormData = (data, { canSaveMidTerm = false, questionsData = null } = {}) => {
    return Object.fromEntries(
        Object.entries(data).filter(([k, _]) => !k.startsWith('other-')).map(([key, value]) => {

            if (canSaveMidTerm) {
                const question = questionsData.steps.reduce((accumulator, currentValue) => {
                    return accumulator.concat(currentValue.items)
                }, []).find(i => i.id === key)

                // checkboxes value should always be an array
                if (['Checkboxes'].includes(question?.type)) {
                    if (typeof value === 'string') {
                        value = [value]
                    }
                }
            }

            if (data.hasOwnProperty(`other-${value}`) && !Array.isArray(value)) {
                return [key, JSON.stringify([{
                    id: value.replace('other-', ''),
                    value: data[`other-${value}`]
                }])]
            } else if (Array.isArray(value)) {
                // Format the checkbox array to the expected JSON structure for CheckboxesField and filter out null values
                const arr = value?.filter(id => !!id)?.map((id) => {
                    if (data.hasOwnProperty(`other-${id}`)) {
                        return {
                            id,
                            value: data[`other-${id}`]
                        };
                    } else {
                        return {id};
                    }
                });
                return [key, arr?.length > 0 ? JSON.stringify(arr) : JSON.stringify([])];
            } else if (typeof value === 'object' && value !== null) {
                // Filter out unchecked RadioButtonsField
                return [key, value?.id ? JSON.stringify(value) : JSON.stringify({})];
            } else {
                // Filter out null values
                return [key, value ? value : ""];
            }
        })
    );
}