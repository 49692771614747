import ReportingModal from '../../Modal/ReportingModal';
import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {Spinner, SpinnerWrapper} from "../../Spinner/Spinner";
import Report from "../Report/Report";
import {ReactComponent as ExpandIcon} from "../../../assets/icons/expand.svg";
import {ReactComponent as MinimizeIcon} from "../../../assets/icons/minimize.svg";
import {Text} from '../../Text/Text';
import {AppointmentDateTime} from '../Appointment/AppointmentDateTime';
import {AppointmentCountLengthString} from '../Appointment/AppointmentCountLengthString';
import {Statuses} from '../../../constants/enums';
import {SaveButton} from './SaveButton';
import {AppointmentStatus} from '../Appointment/AppointmentStatus';
import {checkIsInThePast} from '../timelineHelpers';
import Api from '../../../api/Api';
import {useOpenModal} from '../../../hooks/useOpenModal';
import {ConfirmBeforeCloseModal} from './ConfirmBeforeCloseModal';
import {ProcessButton} from './ProcessButton';

const StyledAppointmentDateTime = styled(AppointmentDateTime)`
    margin-top: 10px;
`;

const AppointmentInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 20px;
`;

const iconStyles = css`
    width: 40px;
    height: 40px;
    cursor: pointer;
`;

const StyledExpandIcon = styled(ExpandIcon)`
    ${iconStyles};
`;

const StyledMinimizeIcon = styled(MinimizeIcon)`
    ${iconStyles};
`;

const TitleAddition = styled.span`
    font-size: var(--fs-button);
    font-weight: var(--fw-regular);
    margin-left: 12px;
    white-space: nowrap;
`;

export const Reporting = ({isOpen, isExpanded, handleExpand, handleMinimize, handleClose, appointment, patient}) => {
    const [reportStatus, setReportStatus] = useState(Statuses.LOADING);
    const [reportData, setReportData] = useState(null);
    const [, setReportError] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(Statuses.IDLE); // Separate submit status to only display loading indicator on click
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    // Fetch the report
    useEffect(() => {
        if (isOpen) {
            setReportStatus(Statuses.LOADING);

            const controller = new AbortController();

            Api.get(`patient/${patient.id}/timeline/appointment/${appointment.id}/report`, (res) => {
                setReportData(res)
                setReportStatus(Statuses.IDLE);
            }, () => {
                setReportError(true);
                setReportStatus(Statuses.IDLE);
            }, controller);

            return () => {
                Api.abortRequest(controller);
                setReportStatus(Statuses.LOADING);
                setReportData(null);
                setReportError(false);
                setSubmitStatus(Statuses.IDLE);
                setHasUnsavedChanges(false);
            }
        }
        //eslint-disable-next-line
    }, [isOpen, appointment]);

    // Appointment variables
    const isInThePast = checkIsInThePast(appointment.end);
    const isNotProcessed = (appointment.status === "Booked" && isInThePast && !appointment.processed);
    const isDiagnosticsDay = appointment.category === "Diagnostiekdag";
    const isReservedDiagnosticsDay = isDiagnosticsDay && appointment.status === "Reserved";

    // Confirm before close when having unsaved progress
    const {isOpen: isOpenConfirmBeforeClose, handleOpen: handleOpenConfirmBeforeClose, handleClose: handleCloseConfirmBeforeClose} = useOpenModal();
    const shouldConfirmBeforeClose = submitStatus === Statuses.SUBMITTING || submitStatus === Statuses.SILENT_SUBMITTING || hasUnsavedChanges;

    const extendedHandleClose = () => {
        if (shouldConfirmBeforeClose) {
            handleOpenConfirmBeforeClose();
        } else {
            handleClose();
        }
    }

    return (
        <ReportingModal isOpen={isOpen} handleClose={extendedHandleClose} isExpanded={isExpanded} shouldConfirmOnClickUnderlay={shouldConfirmBeforeClose}>
            <ReportingModal.Sticky isExpanded={isExpanded}>
                <ReportingModal.Header
                    handleClose={extendedHandleClose}
                    icons={
                        <>
                            <SaveButton
                                form="timeline-appointment-report-form"
                                submitStatus={submitStatus}
                            />
                            <ProcessButton
                                appointment={appointment}
                                isNotProcessed={isNotProcessed}
                            />
                            {isExpanded
                                ? <StyledMinimizeIcon onClick={handleMinimize} />
                                : <StyledExpandIcon onClick={handleExpand} />
                            }
                        </>
                    }
                >
                    {appointment.title}
                    {(appointment.count || appointment?.["length"]) && <TitleAddition><AppointmentCountLengthString appointment={appointment} /></TitleAddition>}
                </ReportingModal.Header>
                <StyledAppointmentDateTime appointment={appointment} />
                <AppointmentInfo>
                    <div>
                        {(appointment?.practitioners?.length > 0 && appointment.category !== "Diagnostiekdag") &&
                            <div>
                                <Text $noMargin $bold>{appointment?.practitioners?.length > 1 ? "Behandelaars" : "Behandelaar"}</Text>
                                <Text $margin="6px 0 0 0">{appointment.practitioners?.join(", ")}</Text>
                            </div>
                        }
                    </div>

                    <AppointmentStatus
                        appointment={appointment}
                        isInThePast={isInThePast}
                        isNotProcessed={isNotProcessed}
                        isReservedDiagnosticsDay={isReservedDiagnosticsDay}
                    />
                </AppointmentInfo>
            </ReportingModal.Sticky>
            <ReportingModal.Content isExpanded={isExpanded}>
                {reportStatus === Statuses.LOADING ? (
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                ) : (
                    reportData ? (
                        <Report
                            reportData={reportData}
                            appointment={appointment}
                            setSubmitStatus={setSubmitStatus}
                            setHasUnsavedChanges={setHasUnsavedChanges}
                        />
                    ) : (
                        <Text $noMargin>Er is geen verslagformulier gekoppeld.</Text>
                    )
                )}
            </ReportingModal.Content>

            <ConfirmBeforeCloseModal
                isOpen={isOpenConfirmBeforeClose}
                handleConfirm={() => {
                    handleCloseConfirmBeforeClose();
                    handleClose();
                }}
                handleClose={handleCloseConfirmBeforeClose}
            />
        </ReportingModal>
    )
}