import React from 'react';
import ReactDOM from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import Root, {RootFallbackElement, rootLoader, shouldNotRevalidateParentOnPagination, shouldRevalidateRoot} from "./routes/Root/Root";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Login, {loginLoader} from "./routes/Login/Login";
import LoginCallback, {loginCallbackLoader} from "./routes/Login/LoginCallback";
import ErrorBoundary from "./routes/Error/ErrorBoundary";
import ApiFailureFallback from "./routes/Error/ApiFailureFallback";
import NoMatch from "./routes/Error/NoMatch";
import AuthLayout from "./layouts/AuthLayout";
import Patient, {patientAction, patientLoader} from "./routes/Patient/Patient";
import Fallback from "./routes/Fallback/Fallback";
import Questionnaire, {questionnaireLoader} from "./routes/Questionnaire/Questionnaire";
import Answers, {answersLoader} from "./routes/Answers/Answers";
import Questionnaires, {questionnairesAction, questionnairesLoader} from "./routes/Questionnaires/Questionnaires";
import Documents, {documentsAction, documentsLoader} from "./routes/Documents/Documents";
import Questions, {questionsAction, questionsLoader} from "./routes/Questions/Questions";
import QuestionnaireProvider from "./contexts/QuestionnaireContext";
import PublicQuestionnaire, {publicQuestionnaireAction, publicQuestionnaireLoader} from "./routes/PublicQuestionnaire/PublicQuestionnaire";
import PublicQuestionnaireIntroduction from "./routes/PublicQuestionnaire/PublicQuestionnaireIntroduction";
import PublicQuestionnaireStep from "./routes/PublicQuestionnaire/PublicQuestionnaireStep";
import QuestionnaireOverview, {questionnaireOverviewLoader} from "./routes/QuestionnaireOverview/QuestionnaireOverview";
import Search, {searchLoader} from "./routes/Search/Search";
import {FilterProvider} from "./contexts/FilterContext";
import Notifications, {notificationsAction, notificationsLoader} from "./routes/Notifications/Notifications";
import GuestLayout from "./layouts/GuestLayout";
import PatientsOverview, {patientsOverviewLoader} from "./routes/PatientsOverview/PatientsOverview";
import PortalLayout, {portalLoader} from "./layouts/PortalLayout";
import NotificationCenter, {notificationCenterAction, notificationCenterLoader} from "./routes/NotificationCenter/NotificationCenter";
import NotificationCenterMessages, {notificationCenterMessagesLoader} from "./routes/NotificationCenterMessages/NotificationCenterMessages";
import SentGroupNotification, {sentGroupNotificationLoader} from "./routes/SentGroupNotification/SentGroupNotification";
import SentNotifications, {sentNotificationsLoader} from "./routes/Notifications/SentNotifications";
import PlannedNotifications, {plannedNotificationsAction, plannedNotificationsLoader} from "./routes/Notifications/PlannedNotifications";
import NotificationCenterMessagesList, {plannedNotificationCenterMessagesLoader, sentNotificationCenterMessagesLoader} from "./routes/NotificationCenterMessages/NotificationCenterMessagesList";
import Appointments, {appointmentsAction, appointmentsLoader} from "./routes/Appointments/Appointments";
import WaitingList, {waitingListAction, waitingListLoader} from "./routes/WaitingList/WaitingList";
import Reservations, {reservationsAction, reservationsLoader} from "./routes/Reservations/Reservations";
import {ReserveAppointment, reserveAppointmentLoader, reserveAppointmentAction} from "./routes/ReserveAppointment/ReserveAppointment";
import WaitingListTable, {waitingListTableLoader} from "./routes/WaitingList/WaitingListTable";
import Summary, {summaryLoader} from "./routes/Summary/Summary";
import PersonalDetails, {personalDetailsAction, personalDetailsLoader} from "./routes/PersonalDetails/PersonalDetails";

const router = createBrowserRouter([
    {
        path: '/',
        loader: rootLoader,
        shouldRevalidate: shouldRevalidateRoot,
        element: <Root />,
        errorElement: <ApiFailureFallback />,
        id: "root",
        children: [
            {
                element: <AuthLayout />,
                children: [
                    {
                        path: "/login?",
                        loader: loginLoader,
                        element: <Login />,
                        errorElement: <ErrorBoundary />,
                    },
                    {
                        path: "/oauth/callback",
                        loader: loginCallbackLoader,
                        element: <LoginCallback />,
                        errorElement: <ErrorBoundary />,
                    },
                ],
            },
            {
                element: <PortalLayout />,
                errorElement: <ErrorBoundary />,
                loader: portalLoader,
                shouldRevalidate: shouldNotRevalidateParentOnPagination,
                id: "portal",
                children: [
                    {
                        path: "/patients/overview",
                        loader: patientsOverviewLoader,
                        element: <PatientsOverview/>,
                        errorElement: <ErrorBoundary/>,
                    },
                    {
                        path: "/berichtencentrum",
                        loader: notificationCenterLoader,
                        action: notificationCenterAction,
                        element: <NotificationCenter />,
                        errorElement: <ErrorBoundary />
                    },
                    {
                        path: "/berichtencentrum/verstuurde-berichten",
                        loader: notificationCenterMessagesLoader,
                        shouldRevalidate: shouldNotRevalidateParentOnPagination,
                        element: <NotificationCenterMessages />,
                        errorElement: <ErrorBoundary />,
                        children: [
                            {
                                index: true,
                                loader: sentNotificationCenterMessagesLoader,
                                element: <NotificationCenterMessagesList />,
                                errorElement: <ErrorBoundary />,
                            },
                            {
                                path: "ingepland",
                                loader: plannedNotificationCenterMessagesLoader,
                                element: <NotificationCenterMessagesList />,
                                errorElement: <ErrorBoundary />,
                            }
                        ]
                    },
                    {
                        path: '/berichtencentrum/verstuurde-berichten/:groupNotificationUUID',
                        loader: sentGroupNotificationLoader,
                        element: <SentGroupNotification />,
                        errorElement: <ErrorBoundary />
                    },
                    {
                        path: "/questionnaire/overview",
                        loader: questionnaireOverviewLoader,
                        element: <QuestionnaireOverview/>,
                        errorElement: <ErrorBoundary/>,
                    },
                    {
                        path: "/reservation/waiting-list",
                        loader: waitingListLoader,
                        action: waitingListAction,
                        shouldRevalidate: shouldNotRevalidateParentOnPagination,
                        element: <WaitingList />,
                        errorElement: <ErrorBoundary />,
                        children: [
                            {
                                index: true,
                                loader: waitingListTableLoader,
                                element: <WaitingListTable />,
                                errorElement: <ErrorBoundary />,
                            },
                            {
                                path: "invited",
                                loader: waitingListTableLoader,
                                element: <WaitingListTable />,
                                errorElement: <ErrorBoundary />,
                            }
                        ]
                    },
                    {
                        path: "/reservation/reservations/:status",
                        loader: reservationsLoader,
                        action: reservationsAction,
                        element: <Reservations />,
                        errorElement: <ErrorBoundary />
                    },
                    {
                        path: "/patient/search",
                        loader: searchLoader,
                        element: <Search />,
                        errorElement: <ErrorBoundary/>,
                    },
                    {
                        path: "/patient/:patientUUID",
                        loader: patientLoader,
                        action: patientAction,
                        element: <Patient />,
                        errorElement: <ErrorBoundary />,
                        id: "patient",
                        children: [
                            {
                                index: true,
                                loader: summaryLoader,
                                element: <Summary />,
                                errorElement: <ErrorBoundary />,
                            },
                            {
                                path: "personalia",
                                loader: personalDetailsLoader,
                                action: personalDetailsAction,
                                element: <PersonalDetails />,
                                errorElement: <ErrorBoundary />,
                            },
                            {
                                path: "vragenlijsten",
                                loader: questionnairesLoader,
                                action: questionnairesAction,
                                element: <Questionnaires />,
                                errorElement: <ErrorBoundary />,
                            },
                            {
                                path: "documenten",
                                loader: documentsLoader,
                                action: documentsAction,
                                element: <Documents />,
                                errorElement: <ErrorBoundary />,
                            },
                            {
                                path: "berichten",
                                loader: notificationsLoader,
                                action: notificationsAction,
                                element: <Notifications />,
                                errorElement: <ErrorBoundary />,
                                children: [
                                    {
                                        index: true,
                                        loader: sentNotificationsLoader,
                                        element: <SentNotifications />,
                                        errorElement: <ErrorBoundary />,
                                    },
                                    {
                                        path: "ingepland",
                                        loader: plannedNotificationsLoader,
                                        action: plannedNotificationsAction,
                                        element: <PlannedNotifications />,
                                        errorElement: <ErrorBoundary />,
                                    }
                                ],
                            },
                            {
                                path: "afspraken",
                                loader: appointmentsLoader,
                                action: appointmentsAction,
                                element: <Appointments/>,
                                errorElement: <ErrorBoundary/>,
                            }
                        ]
                    },
                    {
                        path: "/patient/:patientUUID/afspraak-reserveren",
                        loader: reserveAppointmentLoader,
                        action: reserveAppointmentAction,
                        element: <ReserveAppointment />,
                        errorElement: <ErrorBoundary />
                    },
                    {
                        path: "/patient/:patientUUID/vragenlijst/:questionnaireUUID",
                        loader: questionnaireLoader,
                        element: <Questionnaire />,
                        errorElement: <ErrorBoundary />,
                        id: "questionnaire",
                        children: [
                            {
                                path: "answers",
                                loader: answersLoader,
                                element: <Answers />,
                                errorElement: <ErrorBoundary />,
                            },
                            {
                                path: "invullen",
                                loader: questionsLoader,
                                action: questionsAction,
                                element: <Questions />,
                                errorElement: <ErrorBoundary />,
                            }
                        ],
                    },
                    {
                        path: "/patient-niet-gevonden",
                        element: <Fallback />,
                    }
                ],
            },
            {
                element: <GuestLayout />,
                children: [
                    {
                        path: "/vragenlijst/:uuid",
                        loader: publicQuestionnaireLoader,
                        action: publicQuestionnaireAction,
                        element: <PublicQuestionnaire />,
                        errorElement: <ErrorBoundary />,
                        children: [
                            {
                                index: true,
                                element: <PublicQuestionnaireIntroduction />,
                            },
                            {
                                path: ":step",
                                element: <PublicQuestionnaireStep />,
                            },
                        ]
                    },
                    {
                        path: "/*",
                        element: <NoMatch />,
                    }
                ],
            }
        ],
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        {/*<React.StrictMode>*/}
            <HelmetProvider>
                <FilterProvider>
                    <QuestionnaireProvider>
                        <RouterProvider router={router} fallbackElement={<RootFallbackElement />} />
                    </QuestionnaireProvider>
                </FilterProvider>
            </HelmetProvider>
        {/*</React.StrictMode>*/}
    </>
);