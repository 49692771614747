import {ReactComponent as ExclamationMark} from '../../assets/icons/exclamation-mark.svg';
import styled from 'styled-components';
import {Text} from '../Text/Text';
import Tooltip from "../Tooltip/Tooltip";
import React from "react";

const StyledAlert = styled.div``;

const ExclamationMarkIcon = styled(ExclamationMark)`
    width: 20px;
    height: 20px;
    vertical-align: bottom;
    margin-right: 10px;
`;

const StyledText = styled(Text).attrs({
    $bold: true,
    $noMargin: true
})`
    display: inline-block;
    color: var(--color-alert);
`;

export const Alert = ({tooltipText, tooltipWidth, children}) => {
    return (
        <StyledAlert>
            { tooltipText ? (
                <Tooltip normalWrap={true} width={tooltipWidth} content={tooltipText}>
                    <ExclamationMarkIcon />
                </Tooltip>
            ) : (
                <ExclamationMarkIcon />
            ) }
            <StyledText>{children}</StyledText>
        </StyledAlert>
    );
}