import React from 'react';
import styled, {css} from 'styled-components';
import {Text} from '../../Text/Text';
import {getBorderColor, getBorderStyle} from '../timelineHelpers';

const StyledAppointmentStatus = styled(Text).attrs(props => ({
    $align: "center",
    $bold: !props.$isReservedDiagnosticsDay,
    $noMargin: true
}))`
    flex-shrink: 0;
    padding: 8px 16px;
    border-radius: 999px;
    background-color: ${({$isReservedDiagnosticsDay}) => $isReservedDiagnosticsDay && 'var(--color-timeline-reserved-diagnostiekdag-background)'};
    color: ${({$isReservedDiagnosticsDay}) => $isReservedDiagnosticsDay && 'var(--color-dark-rgb-50)'} !important;
    ${({$isNotProcessed}) => $isNotProcessed ? css`
        border: 2px solid var(--color-unprocessed);
        background-color: var(--color-unprocessed) !important;
        color: var(--color-white);
    ` : css`
        border: 2px ${({$status, $isInThePast}) => getBorderStyle($status, $isInThePast)} ${({$category, $status}) => getBorderColor($category, $status)};
        color: ${({$category, $status}) => getBorderColor($category, $status)};
    `};
`;

export const AppointmentStatus = ({className, appointment, isInThePast, isNotProcessed, isReservedDiagnosticsDay}) => {
    const getStatus = (appointment, isNotProcessed, isInThePast, isReservedDiagnosticsDay) => {
        if (appointment.status === 'Cancelled') {
            if (appointment.statusDescription?.length > 0) {
                if (appointment.statusDescription === "CancelledByPatient") {
                    return "Geannuleerd (patiënt)"
                } else if (appointment.statusDescription === "CancelledByADHDcentraal") {
                    return "Geannuleerd (ADHDcentraal)"
                }
            }

            return "Geannuleerd"
        }

        if (appointment.status === 'Noshow') {
            return "No-show"
        }

        if (isReservedDiagnosticsDay) {
            return "Gereserveerd"
        }

        if (isInThePast) {
            if (isNotProcessed) {
                return "Niet verwerkt"
            } else {
                return "Verwerkt"
            }
        }

        if (appointment.status === 'Booked') {
            return "Gepland"
        }
    }

    return (
        <StyledAppointmentStatus
            className={className}
            $isInThePast={isInThePast}
            $status={appointment.status}
            $category={appointment.category}
            $isNotProcessed={isNotProcessed}
            $isReservedDiagnosticsDay={isReservedDiagnosticsDay}
        >
            { getStatus(appointment, isNotProcessed, isInThePast, isReservedDiagnosticsDay) }
        </StyledAppointmentStatus>
    );
}