import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import {ReactComponent as Bell} from '../../assets/icons/bell.svg';
import {useOpenModal} from '../../hooks/useOpenModal';
import {AspectRatio} from '../AspectRatio/AspectRatio';
import {Heading2, SubHeading} from '../Heading/Heading';
import NewsSideDrawer from '../SideDrawer/NewsSideDrawer';
import {MarkdownText} from '../Text/Text';
import ReactPlayer from "react-player";
import Api from "../../api/Api";
import {useRouteLoaderData} from "react-router-dom";
import PlayButtonIcon from "../../assets/icons/video-player-icon.svg";
import CloseIcon from "../../assets/icons/close-white.svg";

const StyledWhatsNewButton = styled.button`
    position: relative;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: var(--color-primary-light);
    margin-left: auto;
    cursor: pointer;
    outline: none;
    border: none;

    &:hover {
        background-color: var(--color-primary-light-hover);
    }
`;

const StyledBell = styled(Bell)`
    width: 24px;
    height: 24px;

    path {
        fill: var(--color-primary);
    }
`;

const Unread = styled.div`
    position: absolute;
    top: -4px;
    right: -4px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--color-blue-50);
    white-space: nowrap;
    border-radius: 50%;
    color: var(--color-white);
    font-size: 1rem;
    font-weight: var(--fw-bold);
`;

const Item = styled.div`
    margin: 30px 0;
`;

const ImageWrapper = styled.div`
    cursor: pointer;
    position: relative;
    margin-bottom: 30px;
`;

const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 485px;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    cursor: pointer;
    z-index: 0;
    margin-bottom: 30px;
`;

const ItemContent = styled.div`
    padding: 0 30px 0 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const FullscreenModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FullscreenImage = styled.img`
    max-width: 85vw;
    max-height: 85vh;
    object-fit: contain;
`;

const FullscreenContainer = styled.div`
    position: relative;
    display: inline-block;
`;

const ScrollableContainer = styled.div`
    overflow-y: auto;
    height: 100%;
`

const PlayButton = styled.img`
    max-height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`

const NoItems = styled.div`
    margin: 0 auto;
`;

const CloseImage = styled.img`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
`;

const ErrorText = styled(SubHeading)`
    color: red;
    margin: 0 auto;
`

const Divider = styled.hr`
    border: none;
    height: 2px;
    margin: 0 30px;
    background-color: var(--color-divider);
`;

export function WhatsNewButton({data}) {
    const {account} = useRouteLoaderData("root");
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const [fullscreenFile, setFullScreenFile] = useState({})

    const [items, setItems] = useState(data.items || []);
    const [currentPage, setCurrentPage] = useState(2);
    const [hasMore, setHasMore] = useState(true);
    const drawerRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hideIndicator, setHideIndicator] = useState(false);

    const loadMoreItems = async () => {
        if (loading || !hasMore) return;

        setLoading(true);
        try {
            await Api.get(`news?page=${currentPage}`, (res) => {
                const newItems = res.items;

                if (newItems.length === 0) {
                    setHasMore(false);
                } else {
                    setItems((prevItems) => [...prevItems, ...newItems]);
                    setCurrentPage((prevPage) => prevPage + 1);
                }
            });
        } catch (error) {
            console.error('Error loading more items:', error);
        } finally {
            setLoading(false);
        }
    };

    const onScroll = () => {
        if (!drawerRef.current || loading || !hasMore) return;

        const {scrollTop, scrollHeight, clientHeight} = drawerRef.current;

        if (scrollTop + clientHeight >= scrollHeight - 50) {
            loadMoreItems().then(() => {
                console.log('More items loaded.');
            }).catch((error) => {
                console.error('Error loading more items:', error);
            });
        }
    };

    const handleUpdateLastSeen = async () => {
        try {
            await Api.get(`news`, (res) => {
                setItems(res.items)
            });
        } catch (error) {
            setError(error)
        }

        setHideIndicator(true)
        handleOpen();

        try {
            await Api.post(`api/v1/news/lastSeen`, null);
        } catch (error) {
            setError(error)
        }
    };

    const showNewsItemsIndicator = () => {
        if (!data.items.length) return false;
        if (hideIndicator) return false;

        const newestItemDate = new Date(data.items[0].publishedAt);
        const lastSeenNewsItemAt = new Date(account?.lastSeenNewsItemsAt);

        return newestItemDate > lastSeenNewsItemAt;
    };

    return (
        <>
            <StyledWhatsNewButton onClick={handleUpdateLastSeen}>
                <StyledBell/>
                { showNewsItemsIndicator() &&
                    <Unread>!</Unread>
                }
            </StyledWhatsNewButton>

            <NewsSideDrawer isOpen={isOpen} handleClose={handleClose}>
                {error && <ErrorText>{error}</ErrorText>}
                { items.length > 0 ?
                    <ScrollableContainer ref={drawerRef} onScroll={onScroll}>
                        {items.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <Item index={index}>
                                    {/* Render image if file exists and is an image */}
                                    {item.file?.type?.startsWith('image') && item.file?.url && (
                                        <ImageWrapper onClick={() => setFullScreenFile({file: item.file?.url, type: 'image'})}>
                                            <AspectRatio src={item.file.url}/>
                                        </ImageWrapper>
                                    )}

                                    {/* Render video if file exists and is a video */}
                                    {item.file?.type?.startsWith('video') && item.file?.url && (
                                        <VideoWrapper onClick={() => setFullScreenFile({file: item.file?.url, type: 'video'})}>
                                            <PlayButton src={PlayButtonIcon}></PlayButton>
                                            <ReactPlayer width={'485px'} height="100%" style={{ position: 'absolute', top: 0, left: 0 }} url={item.file?.url}/>
                                        </VideoWrapper>
                                    )}

                                    <ItemContent>
                                        <Heading2 $noMargin>{item.title}</Heading2>
                                        <SubHeading $noMargin>{item.date}</SubHeading>
                                        <MarkdownText text={item.body} $noMargin />
                                    </ItemContent>
                                </Item>
                                {index < items.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </ScrollableContainer> :
                    <NoItems>
                        <Heading2>
                            Er zijn op dit moment geen berichten.
                        </Heading2>
                    </NoItems>
                }
                {fullscreenFile.file && (
                    <FullscreenModal onClick={() => setFullScreenFile({})}>
                        <FullscreenContainer onClick={(e) => e.stopPropagation()}>
                            <CloseImage
                                src={CloseIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFullScreenFile({});
                                }}
                            />
                            {fullscreenFile.type === 'video' ? (
                                <ReactPlayer
                                    url={fullscreenFile.file}
                                    playing
                                    controls
                                    width="90%"
                                    height="90%"
                                    config={{
                                        file: {
                                            attributes: {
                                                style: {
                                                    objectFit: 'contain', // or 'cover', 'fill', etc.
                                                },
                                            },
                                        },
                                    }}
                                />
                            ) : (
                                <FullscreenImage
                                    src={fullscreenFile.file}
                                    alt="Fullscreen preview"
                                />
                            )}
                        </FullscreenContainer>
                    </FullscreenModal>
                )}
            </NewsSideDrawer>
        </>
    );
}