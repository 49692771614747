import {TooltipArrow, TooltipRoot, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger} from './NewTooltipBase';
import styled from 'styled-components';
import {ReactComponent as CustomTooltipArrow} from '../../assets/custom-tooltip-arrow.svg';

const StyledNewTooltip = styled.div`
    display: inline-block;
`;

const InnerTooltipArrow = styled.div`
    width: 44px;
`;

const StyledCustomTooltipArrow = styled(CustomTooltipArrow)`
    width: 28px;
    height: 14px;
`;

export const NewTooltip = ({className, children, content, side, align}) => {

    return (
        <StyledNewTooltip className={className}>
            <TooltipProvider>
                <TooltipRoot delayDuration={0} disableHoverableContent={true}>
                    <TooltipTrigger asChild>
                        {children}
                    </TooltipTrigger>
                    <TooltipPortal>
                        <TooltipContent side={side} align={align} arrowPadding={8} >
                            <TooltipArrow asChild={true}>
                                <InnerTooltipArrow>
                                    <StyledCustomTooltipArrow />
                                </InnerTooltipArrow>
                            </TooltipArrow>
                            {content}
                        </TooltipContent>
                    </TooltipPortal>
                </TooltipRoot>
            </TooltipProvider>
        </StyledNewTooltip>
    );
}