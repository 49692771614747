import {ReactComponent as CheckIcon} from '../../../assets/icons/check2.svg';
import styled from 'styled-components';
import {useOpenModal} from '../../../hooks/useOpenModal';
import FormModal from '../../Modal/FormModal';
import React, {useEffect, useState} from 'react';
import {Statuses} from '../../../constants/enums';
import {useForm} from 'react-hook-form';
import {useFetcher, useParams} from 'react-router-dom';
import {Button, buttonVariants} from '../../Button/Button';
import {FormField} from '../../FormFields/FormFields';
import {Text} from '../../Text/Text';
import dayjs from 'dayjs';
import {NewTooltip} from '../../NewTooltip/NewTooltip';

const StyledProcessButton = styled.button`
    background-color: ${({$isNotProcessed}) => $isNotProcessed ? "rgba(var(--color-blue-50-rgb), .1)" : "var(--color-grey-20)"};
    border: none;
    cursor: pointer;
    border-radius: 8px;
    padding: 8px;
`;

const StyledCheckIcon = styled(CheckIcon)`
    display: block;
    width: 24px;
    height: 24px;

    path {
        fill: ${({$isNotProcessed}) => $isNotProcessed ? "var(--color-blue-50)" : "rgba(var(--color-dark-rgb), .3)"};
    }
`;

const StyledFormModalHeader = styled(FormModal.Header)`
    margin-bottom: 24px;
`;

const Flex = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
`;

const Box = styled.div`
    flex: 1 1 0;
`;

const StyledButton = styled(Button)`
    ${({$variant}) => buttonVariants($variant)};
    border: none;
    width: 100%;
`;

export const ProcessButton = ({className, appointment, isNotProcessed, ...props}) => {
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const [status, setStatus] = useState(Statuses.IDLE);
    const [error, setError] = useState(false);

    const {register, handleSubmit, formState: { errors }, clearErrors} = useForm({
        defaultValues: {
            intent: "timeline-process-appointment",
            appointmentUUID: appointment.id,
            startTime: appointment.start ? dayjs(appointment.start).format("HH:mm") : "",
            endTime: appointment.end ? dayjs(appointment.end).format("HH:mm") : ""
        }
    });

    const extendedHandleClose = () => {
        setError(false);
        setStatus(Statuses.IDLE);
        clearErrors();
        handleClose();
    }

    const fetcher = useFetcher();
    const {patientUUID} = useParams();

    const onSubmit = (values) => {
        const data = {
            intent: values?.intent,
            appointmentUUID: values?.appointmentUUID,
            startTime: values?.startTime,
            endTime: values?.endTime
        }
        setStatus(Statuses.SUBMITTING);
        setError(false);
        fetcher.submit(data, { method: "POST", action: `/patient/${patientUUID}`});
    }

    // Fetcher callback
    useEffect(() => {
        if (fetcher?.state === "idle") {
            if (fetcher?.data?.error) {
                setStatus(Statuses.IDLE);
                return setError(true);
            }

            if (fetcher?.data) extendedHandleClose();
        }
        //eslint-disable-next-line
    }, [fetcher]);

    const disabled = status === Statuses.LOADING || status === Statuses.REVALIDATING || status === Statuses.SUBMITTING;

    return (
        <>
            <NewTooltip content={isNotProcessed ? "Verwerken" : "De afspraak is al verwerkt. Wijzigingen kunnen gedaan worden in Medicore."} side="bottom" align="center">
                <StyledProcessButton
                    type="button"
                    onClick={handleOpen}
                    className={className}
                    $isNotProcessed={isNotProcessed}
                    {...props}
                >
                    <StyledCheckIcon $isNotProcessed={isNotProcessed} />
                </StyledProcessButton>
            </NewTooltip>

            <FormModal isOpen={isOpen} handleClose={extendedHandleClose} variant="compact">
                <StyledFormModalHeader handleClose={extendedHandleClose}>Afspraak verwerken</StyledFormModalHeader>

                <Text $margin="0 0 20px 0">Bij het verwerken van de afspraak kun je de begin- en eindtijd wijzigen mits deze tijden zijn veranderd. Na het verwerken worden de tijden en de status aangepast in Medicore.</Text>

                <form id="timeline-process-appointment-form" onSubmit={handleSubmit(onSubmit)}>
                    <Flex>
                        <FormField
                            type="TextField"
                            id="StartTime"
                            placeholder=""
                            label="Begintijd"
                            required={true}
                            pattern={/^([01]\d|2[0-3]):([0-5]\d)$/}
                            patternMessage="Ongeldige invoer, gebruik het 24-uurs formaat (HH:mm)"
                            error={errors.startTime}
                            register={register}
                            name="startTime"
                        />

                        <FormField
                            type="TextField"
                            id="EndTime"
                            placeholder=""
                            label="Eindtijd"
                            required={true}
                            pattern={/^([01]\d|2[0-3]):([0-5]\d)$/}
                            patternMessage="Ongeldige invoer, gebruik het 24-uurs formaat (HH:mm)"
                            error={errors.endTime}
                            register={register}
                            name="endTime"
                        />
                    </Flex>
                </form>

                {error && <Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}

                <FormModal.Footer>
                    <Box><StyledButton type="button" onClick={extendedHandleClose} $variant="secondary">Annuleren</StyledButton></Box>
                    <Box><StyledButton type="submit" form="timeline-process-appointment-form" disabled={disabled} loading={disabled}>Verwerken</StyledButton></Box>
                </FormModal.Footer>
            </FormModal>
        </>
    );
}