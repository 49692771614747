import React from 'react';
import {Heading1} from '../../components/Heading/Heading';
import {HTMLText, Text} from '../../components/Text/Text';
import {defer, Outlet, useLoaderData, useParams, useRouteLoaderData} from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {BackButton} from '../../components/BackButton/BackButton';
import {fetchProtectedData} from '../../api/fetch';
import {Button} from '../../components/Button/Button';
import sanitizeHtml from 'sanitize-html';
import {checkAccountPermissions} from '../../utils/helpers';
import {checkRequiredAccount} from '../Root/Root';
import {Permissions} from '../../constants/enums';
import {QuestionnaireResults} from './Partials/QuestionnaireResults';

const QuestionnaireTitle = styled(Heading1)`
    font-size: var(--fs-questionnaire-title);
`;

const ResultsTitle = styled(Heading1).attrs({
    as: "h2"
})`
    font-size: var(--fs-results-title);
`;

const InfoListWrapper = styled.div`
    margin: 20px 0 60px;
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 60px;
    row-gap: 8px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
`;

const InfoList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const InfoListItem = styled(Text).attrs({
    $noMargin: true,
})``;

const ResultsHeading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const PortalDescription = styled.div`
    margin-top: 30px;
`;

export async function questionnaireLoader({request, params}) {
    const questionnairePromise = fetchProtectedData(request, `patient/${params.patientUUID}/questionnaire/${params.questionnaireUUID}`);

    return defer({questionnaireData: await questionnairePromise});
}

const Questionnaire = () => {
    const {account} = useRouteLoaderData("root");
    const {questionnaireData} = useLoaderData();
    const {patientUUID} = useParams();

    const startDate = dayjs(questionnaireData.start);
    const formattedStartDate = startDate.format("DD-MM-YYYY");

    const canRespond = Boolean(questionnaireData.canRespond);
    const isCompleted = Boolean(questionnaireData.completedAt) && questionnaireData.completedAt !== "null";
    const completedAtDate = dayjs(questionnaireData.completedAt);
    const formattedCompletedAtDate = completedAtDate.format("DD-MM-YYYY HH:mm");
    const questionnaireClassification = questionnaireData?.questionnaireType?.toUpperCase();

    return (
        <>
            <BackButton to={`/patient/${patientUUID}/vragenlijsten`} />

            <QuestionnaireTitle $noMargin>Vragenlijst: {questionnaireData.title}</QuestionnaireTitle>
            <InfoListWrapper>
                <InfoList>
                    {questionnaireData?.patient?.name && <InfoListItem><strong>Naam:</strong> {questionnaireData.patient.name}</InfoListItem>}
                    {questionnaireData?.patient?.birthDate && <InfoListItem><strong>Geboortedatum:</strong> {questionnaireData.patient.birthDate}</InfoListItem>}
                </InfoList>
                <InfoList>
                    {questionnaireData.start && <InfoListItem><strong>Datum uitgenodigd:</strong> {formattedStartDate}</InfoListItem>}
                    {isCompleted && <InfoListItem><strong>Datum ingevuld:</strong> {formattedCompletedAtDate}</InfoListItem>}
                </InfoList>
            </InfoListWrapper>

            <ResultsHeading>
                { (checkAccountPermissions(account, `PATIENTQUESTIONNAIRE_CLASSIFICATION_${questionnaireClassification}_READ`) && isCompleted) && <ResultsTitle $noMargin>Resultaten vragenlijst</ResultsTitle> }
                { (checkAccountPermissions(account, `PATIENTQUESTIONNAIRE_CLASSIFICATION_${questionnaireClassification}_EDIT`) && canRespond && !isCompleted) && <ResultsTitle $noMargin>Vragenlijst invullen</ResultsTitle> }
                { (checkAccountPermissions(account, `PATIENTQUESTIONNAIRE_CLASSIFICATION_${questionnaireClassification}_READ`) && isCompleted) && <Button to={"answers"} preventScrollReset={true}>Vragenlijst bekijken</Button> }
                { (checkAccountPermissions(account, `PATIENTQUESTIONNAIRE_CLASSIFICATION_${questionnaireClassification}_EDIT`) && canRespond && !isCompleted) && <Button to={"invullen"} preventScrollReset={true}>Vragenlijst invullen</Button> }
            </ResultsHeading>

            {questionnaireData?.portalDescription &&
                <PortalDescription>
                    <HTMLText dangerouslySetInnerHTML={{__html: sanitizeHtml(questionnaireData.portalDescription)}} />
                </PortalDescription>
            }

            <QuestionnaireResults questionnaireData={questionnaireData} />

            <Outlet />
        </>
    );
}

export default Questionnaire;